import * as z from 'zod';

export const editVcmUnitResponseFormSchema = z
  .object({
    response_method: z
      .enum([
        'current_unit',
        'equivalent_unit',
        'relevant_study',
        'work_experience'
      ])
      .optional(),
    study_detail: z.string().optional(),
    equivalent_unit_code: z.string().optional(),
    equivalent_unit_title: z.string().optional(),
    study_justification: z.string().optional(),
    experience_justification: z.string().optional(),
    work_experiences: z.array(z.object({ id: z.number() })).optional()
  })
  .superRefine(
    (
      {
        response_method,
        study_detail,
        equivalent_unit_code,
        equivalent_unit_title,
        study_justification,
        experience_justification,
        work_experiences
      },
      ctx
    ) => {
      let result = true;

      switch (response_method) {
        case 'equivalent_unit':
          if (!equivalent_unit_code) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['equivalent_unit_code'],
              message: `Required`,
              fatal: true
            });
            result = z.NEVER;
          }
          if (!equivalent_unit_title) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['equivalent_unit_title'],
              message: `Required`,
              fatal: true
            });
            result = z.NEVER;
          }
          break;
        case 'relevant_study':
          if (!study_detail) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['study_detail'],
              message: `Required`,
              fatal: true
            });
            result = z.NEVER;
          }
          if (!study_justification) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['study_justification'],
              message: `Required`,
              fatal: true
            });
            result = z.NEVER;
          }
          break;

        case 'work_experience':
          if (!experience_justification) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['experience_justification'],
              message: `Required`,
              fatal: true
            });
            result = z.NEVER;
          }
          if (!work_experiences?.length) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['work_experiences'],
              message: `Required`,
              fatal: true
            });
            result = z.NEVER;
          }
          break;
      }
      return result;
    }
  );
