import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Grid } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { withStyles } from 'tss-react/mui';
import ApiService from '@common/services/ApiService';
import { DefaultModal } from '@common/components/default-modal';
import { DefaultButton } from '@common/components/default-button';
import WaveTextField from '@common/components/form/WaveTextField';
import WaveRichTextEditor from '@common/components/form/WaveRichTextEditor';
import WaveSelectField from '@common/components/form/WaveSelectField';
import { vcmUnitResponseMethodOptions } from './options';
import { editVcmUnitResponseFormSchema } from './schema';
import WorkExperienceSelect from './WorkExperienceSelect';

const styles = (theme) => ({
  button: {
    marginTop: 16
  }
});

const api = new ApiService();

const UnitResponseUpdateModal = ({
  classes,
  isOpened,
  onClose,
  vcm,
  unitResponse,
  onRefresh
}) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isSubmitting }
  } = useForm({
    resolver: zodResolver(editVcmUnitResponseFormSchema),
    mode: 'onSubmit'
  });

  const handleClose = useCallback(() => {
    setErrorMessage(null);
    onClose();
  }, [setErrorMessage, onClose]);

  useEffect(() => {
    reset({
      response_method: unitResponse?.response_method,
      equivalent_unit_code: unitResponse?.equivalent_unit_code ?? '',
      equivalent_unit_title: unitResponse?.equivalent_unit_title ?? '',
      study_detail: unitResponse?.study_detail ?? '',
      study_justification: unitResponse?.study_justification ?? '',
      experience_justification: unitResponse?.experience_justification ?? '',
      work_experiences: unitResponse?.work_experiences ?? []
    });
  }, [unitResponse, reset]);

  const updateWorkExperiences = async (newWorkExperiences) => {
    const oldWorkExperienceIds =
      unitResponse.work_experiences.map((we) => we.id) ?? [];

    const newWorkExperienceIds = newWorkExperiences.map((we) => we.id);

    const commonWorkExperienceIds = oldWorkExperienceIds.filter((weId) =>
      newWorkExperienceIds.includes(weId)
    );

    const toRemoveWorkExperienceIds = oldWorkExperienceIds.filter(
      (weId) => !commonWorkExperienceIds.includes(weId)
    );
    if (toRemoveWorkExperienceIds.length > 0) {
      await Promise.all(
        toRemoveWorkExperienceIds.map((weId) =>
          api.delete(
            `vcms/${unitResponse.vcm_id}/unit_responses/${unitResponse.unit.id}/work_experiences`,weId
          )
        )
      );
    }

    const toAddWorkExperienceIds = newWorkExperienceIds.filter(
      (weId) => !commonWorkExperienceIds.includes(weId)
    );
    if (toAddWorkExperienceIds.length > 0) {
      await Promise.all(
        toAddWorkExperienceIds.map((weId) =>
          api.post(
            `/api/v1/vcms/${unitResponse.vcm_id}/unit_responses/${unitResponse.unit.id}/work_experiences/${weId}`,{}
          )
        )
      );
    }
  };

  const onSubmit = useCallback(
    async (data) => {
      let toSubmit = { response_method: data.response_method };
      switch (data.response_method) {
        case vcmUnitResponseMethodOptions.equivalent_unit.id:
          toSubmit.equivalent_unit_code = data.equivalent_unit_code;
          toSubmit.equivalent_unit_title = data.equivalent_unit_title;
          break;
        case vcmUnitResponseMethodOptions.relevant_study.id:
          toSubmit.study_detail = data.study_detail;
          toSubmit.study_justification = data.study_justification;
          break;
        case vcmUnitResponseMethodOptions.work_experience.id:
          toSubmit.experience_justification = data.experience_justification;
          break;
      }

      try {
        const url = `/api/v1/vcms/${unitResponse.vcm_id}/unit_responses/${unitResponse.unit.id}`;
        const res = await api.put(url, { vcm_unit_response: data });
        if (res.status === 200) {
          if(data.response_method === vcmUnitResponseMethodOptions.work_experience.id){
            await updateWorkExperiences(data.work_experiences);
          }  
          onRefresh();
          handleClose();
        } else if (res.status === 422 || res.status === 400) {
          setErrorMessage(res.json.message);
        } else {
          console.error('Unknown response', res);
        }
      } catch (error) {
        console.error(error);
        setErrorMessage("Something went wrong!");
      }
    },
    [onRefresh, setErrorMessage, handleClose, unitResponse]
  );

  const response_method = watch('response_method');

  if (!unitResponse || !vcm) {
    return null;
  }

  return (
    <DefaultModal
      isOpened={isOpened}
      isLoading={isSubmitting}
      onClose={handleClose}
      title="Edit response"
      action={
        <Grid container>
          <DefaultButton
            className={classes.button}
            color="secondary"
            onClick={handleSubmit(onSubmit)}
            size="small"
            variant="contained"
          >
            Save
          </DefaultButton>
        </Grid>
      }
    >
      <Grid container spacing={2} mb={4}>
        {errorMessage ? (
          <Grid item xs={12}>
            <Typography color="error">{errorMessage}</Typography>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Typography className={classes.body2}>
            Unit: {unitResponse?.unit?.code}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="response_method"
            control={control}
            render={({ field }) => (
              <WaveSelectField
                noValidation
                label="Response method"
                menuItems={vcmUnitResponseMethodOptions}
                {...field}
              />
            )}
          />
        </Grid>
        {response_method === vcmUnitResponseMethodOptions.equivalent_unit.id ? (
          <>
            <Grid item xs={12}>
              <Controller
                name="equivalent_unit_code"
                control={control}
                render={({ field, fieldState }) => (
                  <WaveTextField
                    label="Code"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="equivalent_unit_title"
                control={control}
                render={({ field, fieldState }) => (
                  <WaveTextField
                    label="Title"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </>
        ) : null}
        {response_method === vcmUnitResponseMethodOptions.relevant_study.id ? (
          <>
            <Grid item xs={12}>
              <Controller
                name="study_detail"
                control={control}
                render={({ field, fieldState }) => (
                  <WaveTextField
                    label="Description"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="study_justification"
                control={control}
                render={({ field, fieldState }) => (
                  <WaveRichTextEditor
                    label="Justification"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </>
        ) : null}
        {response_method === vcmUnitResponseMethodOptions.work_experience.id ? (
          <>
            <Grid item xs={12} my={2}>
              <Controller
                name="work_experiences"
                control={control}
                render={({ field, fieldState }) => (
                  <WorkExperienceSelect
                    ownerUserId={vcm.owner_user.id}
                    setErrorMessage={setErrorMessage}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="experience_justification"
                control={control}
                render={({ field, fieldState }) => (
                  <WaveRichTextEditor
                    label="Justification"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </DefaultModal>
  );
};

export default withStyles(UnitResponseUpdateModal, styles, {
  withTheme: true
});
