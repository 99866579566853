import React from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import WaveTextField from '@common/components/form/WaveTextField';
import { DefaultCheckbox } from '@common/components/default-checkbox';
import MetadataService from '@common/services/MetadataService';

const styles = (theme) => ({
  container: {
    padding: theme.typography.pxToRem(25),
    paddingTop: theme.typography.pxToRem(22)
  },
  header: {
    paddingBottom: theme.typography.pxToRem(12)
  },
  tooltipContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '2px',
    boxShadow: '0 2px 10px 0 #00000028',
    padding: theme.typography.pxToRem(20),
    opacity: 1
  },
  tooltipText: {
    color: '#0c0606de',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5
  },
  popper: {
    opacity: 1
  }
});

class Currency extends React.Component {
  meta = new MetadataService();

  errorHelpers = (field) => {
    if (this.props.validationErrors.hasOwnProperty(field)) {
      return this.props.validationErrors[field][0];
    }
    return null;
  };

  errorStatus = (field) => {
    return this.props.validationErrors.hasOwnProperty(field);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let institute = { ...this.props.instituteDetails };
    institute[name] = value;

    this.props.onChange(institute);
  };

  handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let institute = { ...this.props.instituteDetails };
    if (target.checked) {
      institute[name].push(value);
    } else {
      const idx = institute[name].indexOf(value);
      if (idx > -1) {
        institute[name].splice(idx, 1);
      }
    }

    this.props.onInstituteChange(institute);
  };

  makeFieldProps = (field) => {
    return {
      error: this.errorStatus(field),
      helperText: this.errorHelpers(field),
      name: field,
      onChange: this.handleInputChange,
      value: this.props.instituteDetails[field]
    };
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.container}>
        <form noValidate autoComplete="off">
          <Typography className={classes.header} variant="h6">
            Currency
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <WaveTextField
                label="Time Vocational Currency Considered Valid (Months)"
                tooltipProps={{
                  placement: 'top',
                  titleComponent: (
                    <Typography className={classes.tooltipText}>
                      Educators are considered 'current' for a unit if they have
                      approved Vocational Currency with an Activity Date within
                      the number of months specified here.
                    </Typography>
                  ),
                  tooltipContainer: classes.tooltipContainer,
                  tooltipPopper: classes.popper
                }}
                {...this.makeFieldProps('vocational_currency_valid_month_count')}
              />
            </Grid>
            <Grid item xs={12}>
              <DefaultCheckbox
                label="Use VCM unit codes for Vocational Currency"
                checkboxValue={true}
                unCheckValue={false}
                {...this.makeFieldProps('vocational_currency_uses_units')}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
}

export default withStyles(Currency, styles, {
  withTheme: true
});
