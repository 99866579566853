import React, { Fragment } from 'react';
import { Typography, IconButton } from '@mui/material';
import format from 'date-fns/format';
import { withStyles } from 'tss-react/mui';
import {
  listsPlugin,
  MDXEditor,
  quotePlugin,
  headingsPlugin
} from '@mdxeditor/editor';
import EditIcon from '@common/icons/EditIcon';
import FileIcon from '@common/icons/FileIcon';
import { unitResponsesStyles } from './styles';
import { vcmUnitResponseMethodOptions, vcmStatusOptions } from './options';

const MDXPlugins = [quotePlugin(), listsPlugin(), headingsPlugin()];

const CurrentUnitResponse = ({ classes }) => {
  return (
    <Typography className={classes.smallLabel}>
      {vcmUnitResponseMethodOptions.current_unit.label}
    </Typography>
  );
};

const EquivalentUnitResponse = ({ classes, unitResponse }) => {
  return (
    <>
      <Typography className={classes.smallLabel}>
        {vcmUnitResponseMethodOptions.equivalent_unit.label}
      </Typography>
      <div>
        <Typography className={classes.label}>Code:</Typography>
        <Typography className={classes.body2}>
          {unitResponse.equivalent_unit_code}
        </Typography>
      </div>
      <div>
        <Typography className={classes.label}>Title:</Typography>
        <Typography className={classes.body2}>
          {unitResponse.equivalent_unit_title}
        </Typography>
      </div>
    </>
  );
};

const RelevantStudyResponse = ({ classes, unitResponse }) => {
  return (
    <>
      <Typography className={classes.smallLabel}>
        {vcmUnitResponseMethodOptions.relevant_study.label}
      </Typography>
      <div>
        <Typography className={classes.label}>Description:</Typography>
        <Typography className={classes.body2}>
          {unitResponse.study_detail}
        </Typography>
      </div>
      <div>
        <Typography className={classes.label}>Justification:</Typography>
        <MDXEditor
          plugins={MDXPlugins}
          readOnly
          markdown={unitResponse.study_justification}
        />
      </div>
    </>
  );
};

const WorkExperienceResponse = ({ classes, unitResponse }) => {
  const workExperiences = unitResponse.work_experiences || [];
  if (!workExperiences.length) {
    return null;
  }

  return (
    <>
      <Typography className={classes.smallLabel}>
        {vcmUnitResponseMethodOptions.work_experience.label}
      </Typography>
      <div>
        {workExperiences.map((we, index) => (
          <div key={we.id ?? index} className={classes.borderedContainer}>
            <Typography className={classes.smallLabel}>
              {we.employer_name}
            </Typography>
            <Typography color={'textSecondary'} className={classes.body2}>
              {we.job_role}
            </Typography>
            <Typography color={'textSecondary'} className={classes.body2}>
              {we.year_from} - {we.year_to}
            </Typography>
          </div>
        ))}
      </div>

      <div>
        <Typography className={classes.label}>Justification:</Typography>
        <MDXEditor
          plugins={MDXPlugins}
          readOnly
          markdown={unitResponse.experience_justification}
        />
      </div>
    </>
  );
};

const SupportingDocuments = ({ classes, supporting_documents }) => {
  const supportingDocuments = supporting_documents || [];
  if (!supportingDocuments.length) {
    return null;
  }

  return (
    <div>
      <Typography className={classes.label}>Supporting documents:</Typography>
      {supportingDocuments.map((doc, index) => (
        <Fragment key={doc.id ?? index}>
          <div className={classes.row}>
            <FileIcon viewBox="0 0 16 16" />
            <div>
              <Typography className={classes.body2}>{doc.title}</Typography>
              <a href={doc.url} target="_blank" rel="noopener noreferrer">
                <Typography className={[classes.body2, classes.link]}>
                  {doc.filename}
                </Typography>
              </a>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

const UnitResponseCurrency = ({ classes, currency }) => {
  if (!currency) {
    return null;
  }

  const formattedDate = currency.updated_at
    ? format(new Date(currency.updated_at), 'dd/MM/yy')
    : null;

  switch (currency.status) {
    case 'current':
      return (
        <div>
          <Typography className={classes.label}>Current</Typography>
          {formattedDate ? (
            <Typography className={classes.body2}>{formattedDate}</Typography>
          ) : null}
        </div>
      );
    case 'missing':
      return (
        <Typography className={classes.label}>Missing Currency</Typography>
      );
    case 'outdated':
      return (
        <div>
          <Typography className={classes.label}>Outdated Currency</Typography>
          {formattedDate ? (
            <Typography className={classes.body2}>{formattedDate}</Typography>
          ) : null}
        </div>
      );
    default:
      return null;
  }
};

const EditIconButton = (props) => {
  return (
    <IconButton size="small" sx={{ p: 0 }} {...props}>
      <EditIcon />
    </IconButton>
  );
};

const VcmUnitResponseDetailCell = ({
  classes,
  row,
  vcm,
  onEdit,
  institute
}) => {
  if (!vcm || row.removed) {
    return null;
  }

  const renderResponseMethod = () => {
    switch (row.response_method) {
      case vcmUnitResponseMethodOptions.current_unit.id:
        return <CurrentUnitResponse classes={classes} unitResponse={row} />;
      case vcmUnitResponseMethodOptions.equivalent_unit.id:
        return <EquivalentUnitResponse classes={classes} unitResponse={row} />;
      case vcmUnitResponseMethodOptions.relevant_study.id:
        return <RelevantStudyResponse classes={classes} unitResponse={row} />;
      case vcmUnitResponseMethodOptions.work_experience.id:
        return <WorkExperienceResponse classes={classes} unitResponse={row} />;
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.cell}>
        {renderResponseMethod()}
        <SupportingDocuments
          classes={classes}
          supporting_documents={row.supporting_documents}
        />
        {institute.vocational_currency_uses_units ? (
          <UnitResponseCurrency classes={classes} currency={row.currency} />
        ) : null}
      </div>
      <div>
        <EditIconButton
          onClick={() => {
            onEdit?.(row);
          }}
          disabled={vcm.status !== vcmStatusOptions.in_review.id}
        />
      </div>
    </div>
  );
};

export default withStyles(VcmUnitResponseDetailCell, unitResponsesStyles, {
  withTheme: true
});
